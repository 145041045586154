@tailwind base;
@tailwind components;
@tailwind utilities;




/* Custom styles */

h2{
  font-size: 2rem;
}
/* a {
  color: blue; 
  text-decoration: underline; 
} */
button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline-block */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Add some margin */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 4px; /* Rounded corners */
}

/* Hover state */
button:hover {
  background-color: #45a049; /* Darker green background */
}

.player-card {
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  padding: 16px; /* Padding inside the box */
  margin-bottom: 16px; /* Space between player cards */
  background-color: #f9f9f9; /* Light background color */
}

.player-card ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

.player-card li {
  margin-bottom: 8px; /* Space between list items */
}

.player-card a {
  font-size: 1.2rem; /* Larger font size for player names */
  font-weight: bold; /* Bold player names */
  text-decoration: none; /* Remove underline */
  color: #007BFF; /* Link color */
}

.player-card a:hover {
  text-decoration: underline; /* Underline on hover */
}

.team-name {
  font-size: 2rem;
}

/* src/components/Pchamps.css */

.winner-team-name {
  font-size: 2rem;
  font-weight: bold;
  color: #ffd900;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 50%;
  }
}

@keyframes flame {
  0% {
    box-shadow: 0 0 10px 0 rgba(255, 69, 0, 0.8), 0 0 20px 0 rgba(255, 140, 0, 0.6), 0 0 30px 0 rgba(255, 69, 0, 0.4);
  }
  50% {
    box-shadow: 0 0 20px 0 rgba(255, 69, 0, 0.8), 0 0 40px 0 rgba(255, 140, 0, 0.6), 0 0 60px 0 rgba(255, 69, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 10px 0 rgba(255, 69, 0, 0.8), 0 0 20px 0 rgba(255, 140, 0, 0.6), 0 0 30px 0 rgba(255, 69, 0, 0.4);
  }
}

/* .flame-border {
  position: relative;
  border: 2px solid transparent;
  animation: flame 6s infinite;
  padding: 5px;
  border-radius: 8px;
}

.flame-border::before {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: 10px;
  z-index: -1;
  background: linear-gradient(45deg, rgba(255, 69, 0, 0.8), rgba(255, 140, 0, 0.6));
  filter: blur(15px);
  animation: flame 2s infinite;
} */

/* styles.css */

